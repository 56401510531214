<template>
  <div>
    <h1 class="page__title">
      {{ pageTitle }}<br />Налаштуйте систему, щоб почати роботу.
    </h1>
    <div class="text-card">
      <div class="text-card__title">
        Додайте інформацію про компанію та своїх контрагентів
      </div>
      <div class="step__row">
        <div class="step__item">
          <div class="step__circle"><span>1</span></div>
          <div class="step__title">
            Створіть
            <router-link
              @mouseover.native="showFinancialModelInfo = true"
              @mouseleave.native="showFinancialModelInfo = false"
              class="text-help"
              :to="{ path: '/entity/financial-models' }"
              >Профіль СПФМ</router-link
            >
          </div>
        </div>
        <div class="step__item">
          <div class="step__circle"><span>2</span></div>
          <div class="step__title">
            Додайте <br /><router-link :to="{ path: '/entity/counterpart' }"
              >контрагентів</router-link
            >
          </div>
        </div>
        <div class="step__item">
          <div class="step__circle"><span>3</span></div>
          <div class="step__title">
            Додайте
            <router-link :to="{ path: '/entity/contract' }"
              >договори</router-link
            >
            з контрагентами
          </div>
        </div>
        <div class="step__line"></div>
      </div>
      <div
        v-show="showFinancialModelInfo"
        class="text-help__desc text_dedicated"
      >
        Профіль СПФМ — це ваш суб'єкт первинного фінансового моніторингу у
        системі. Її необхідно створити, щоб вести облік контрагентів, формувати
        статистичну звітність до НБУ, розподіляти ролі у системі і подавати
        повідомлення до Держфінмоніторингу.
      </div>
      <p>
        Будь-який із цих кроків можна виконати вручну або через
        <a href="https://docs.amlpoint.com.ua/" target="_blank">
          інтеграцію за АРІ
        </a>
      </p>
    </div>
    <div class="text-card roles-card">
      <div class="text-card__title">
        Створіть користувачів та визначте їхні ролі в системі
      </div>
      <div class="text-accordion">
        <div
          class="text-accordion__title roles-card__title"
          @click="showRolesInfo = !showRolesInfo"
        >
          Більше про ролі
        </div>
        <div
          v-show="showRolesInfo"
          class="text-accordion__desc text_dedicated roles-card__content"
        >
          <ul>
            <li>
              <b>Власник акаунта (Owner)</b> — роль з максимальними правами, яка
              автоматично закріплюється за електронною адресою, вказаною під час
              реєстрації у системі. Власник може додавати нових користувачів та
              призначати ролі, створювати профілі СПФМ, контрагентів та
              договори.Користувача Owner неможливо видалити із системи, а роль
              замінити на іншу.
            </li>
            <li>
              <b>Співробітник (Editor)</b> може створювати нових контрагентів та
              договори
            </li>
            <li>
              <b>Перевіряючий (Viewer)</b> може лише переглядати інформацію,
              права на редагування відсутні в усіх розділах меню.
            </li>
            <li>
              <b>Гість (Guest)</b> — технічна роль, яка присвоюється одразу
              після реєстрації. Залишається до моменту, поки адміністратор її не
              змінить на роль Власник.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="text-card">
      <div class="text-card__title">
        Після налаштувань ви зможете використовувати додаткові можливості
        AML.point, які залежать від версії програми
      </div>
      <div class="func-accordion func-accordion_base">
        <div class="func-accordion__title">Функціонал базової версії</div>
        <div class="func-accordion__content">
          <ul>
            <li>
              <b>Статзвітність</b> — автоматична побудова звітів
              <a
                target="_blank"
                href="https://bank.gov.ua/ua/statistic/nbureport/regindicator-statreport/files-24"
                >за актуальними вимогами НБУ</a
              >.<br />
              Формуйте звіт у декілька кліків у потрібному форматі:
            </li>
            <ul>
              <li>
                2LX «Дані про обсяги, спосіб та мету переказу для оцінки ризиків
                у сфері фінансового моніторингу»
              </li>
              <li>
                2JX «Дані про взаємодію небанківських установ зі спеціально
                уповноваженим органом»
              </li>
              <li>
                2HX «Інформація з питань управління ризиками у сфері фінансового
                моніторингу»
              </li>
              <li>
                4EX "Інформація оператора послуг платіжної інфраструктури щодо
                обсягу оброблених інформаційних повідомлень"
              </li>
              <li>
                4LX "Дані про розрахунок нормативу короткострокової ліквідності
                небанківських надавачів фінансових платіжних послуг"
              </li>
            </ul>
          </ul>
          <p class="text-blue">
            Ця версія AML.point не підтримує облік операцій та контроль лімітів.
            Ці функції доступні у розширеній версії.
          </p>
        </div>
      </div>
      <div class="func-accordion func-accordion_extended">
        <div class="func-accordion__title">Функціонал розширеної версії</div>
        <div class="func-accordion__content">
          <ul>
            <li>
              <b>Статзвітність</b> — автоматична побудова звітів
              <a
                target="_blank"
                href="https://bank.gov.ua/ua/statistic/nbureport/regindicator-statreport/files-24"
                >за актуальними вимогами НБУ</a
              >.<br />
              Формуйте звіт у декілька кліків у потрібному форматі:
            </li>
            <ul>
              <li>
                2LX «Дані про обсяги, спосіб та мету переказу для оцінки ризиків
                у сфері фінансового моніторингу»
              </li>
              <li>
                2JX «Дані про взаємодію небанківських установ зі спеціально
                уповноваженим органом»
              </li>
              <li>
                2HX «Інформація з питань управління ризиками у сфері фінансового
                моніторингу»
              </li>
              <li>
                4EX "Інформація оператора послуг платіжної інфраструктури щодо
                обсягу оброблених інформаційних повідомлень"
              </li>
              <li>
                4LX "Дані про розрахунок нормативу короткострокової ліквідності
                небанківських надавачів фінансових платіжних послуг"
              </li>
            </ul>
            <li>
              <b>Операції</b> — список платежів за контрагентами. <br />Обирайте
              фільтри та експортуйте звіт у тому вигляді, який потрібен для
              аналізу.
            </li>
            <li>
              <b>Ліміти та контроль</b> — перелік операцій, які заблокувала
              платіжна система через підозру у злочинній діяльності.<br />
              Формуйте перелік заблокованих платежів, щоб оперативно надати
              потрібну інформацію під час перевірки.
            </li>
          </ul>
          <p class="text-card__title">
            Щоб придбати розширену версію програми:
          </p>
          <div class="contact-info">
            <div class="contact-info__row">
              <div class="contact-info__title">Телефонуйте</div>
              <div class="contact-info__link">
                <a href="tel:+380443344765">+380 44 334 47 65</a>
              </div>
            </div>
            <div class="contact-info__row">
              <div class="contact-info__title">Або пишіть на</div>
              <div class="contact-info__link">
                <a href="mailto:partner@aifintech.com.ua"
                  >partner@aifintech.com.ua</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardLine2ChartExample from "./dashboard/CardLine2ChartExample";
import { mapGetters } from "vuex";

export default {
  name: "dashboard",
  components: {
    CardLine2ChartExample,
  },
  data() {
    return {
      showFinancialModelInfo: false,
      showRolesInfo: false,
    };
  },
  computed: {
    pageTitle() {
      if (process.env.VUE_APP_IS_DEMO === "true")
        return "Вітаємо у демо-версії AML.point!";
      return "Вітаємо у системі AML.point!";
    },
  },
};
</script>

<style scoped>
.page__title {
  font-family: ProximaNova;
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  color: #2d205d;
}

.text-card {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 16px;
  margin-top: 20px;
  max-width: 998px;
}

.text-card__title {
  font-family: ProximaNova;
  font-weight: 700 !important;
  font-size: 16px;
  line-height: 24px;
  color: #131f3e;
}

.text-card a {
  text-decoration: underline;
  color: #6752b1;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
}

.text-card p {
  font-family: ProximaNova;
  font-size: 1rem;
  font-weight: 600;
  margin: 20px 0;
}

.text-card .text_dedicated {
  font-family: ProximaNova;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #131f3e;
  background-color: #f6f3fc;
  padding: 8px 16px;
  border-radius: 8px;
  width: 100%;
}

.step__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  max-width: 640px;
  margin-top: 20px;
  position: relative;
}

.step__line {
  background-color: #e4ecff;
  height: 2px;
  width: 80%;
  position: absolute;
  top: 24px;
  z-index: 0;
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
  right: 50%;
}

.step__item {
  max-width: 164px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 1;
  color: #131f3e;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

.step__circle {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #e4ecff;
  margin-bottom: 24px;
}

.step__circle span {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 24px rgba(100, 144, 255, 0.7);
  box-shadow: 0px 4px 24px rgba(100, 144, 255, 0.7);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #294384;
}

.step__title {
  font-family: ProximaNova;
  text-align: center;
}

.text-help {
  text-decoration: underline;
  color: #6752b1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.text-help::after {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background-image: url(/images/text-help.svg);
  margin-left: 4px;
}

.text-help__desc {
  max-width: 712px;
  margin: 20px 0px 30px;
}

.text-accordion {
  margin-top: 20px;
}

.text-accordion__title {
  font-family: ProximaNova;
  font-size: 1rem;
  text-decoration: underline;
  cursor: pointer;
  color: #6752b1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-accordion__title::after {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  background-image: url(/images/chevron-down.svg);
}

.text-accordion__desc {
  margin-top: 20px;
  max-width: 848px;
}

.text-accordion ul li:not(:last-of-type) {
  margin-bottom: 16px;
}

.func-accordion {
  padding: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 16px;
  margin: 20px 0;
}

.func-accordion_extended {
  background-color: #f7f8fb;
  border: 1px solid #b6c4e8;
}

.func-accordion_extended .func-accordion__title {
  font-family: ProximaNova;
  color: #4368c6;
}

.func-accordion_extended_closed {
  overflow-y: hidden;
}

.func-accordion_extended_closed .func-accordion__content {
  max-height: 0px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  visibility: hidden;
  opacity: 0;
}

.func-accordion_base {
  background: #f6f3fc;
  border: 1px solid #d3cdeb;
}

.func-accordion_base .func-accordion__title {
  font-family: ProximaNova;
  color: #6752b1;
}

.func-accordion_base_closed {
  overflow-y: hidden;
}

.func-accordion_base_closed .func-accordion__content {
  max-height: 0px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  visibility: hidden;
  opacity: 0;
}

.func-accordion__title {
  font-family: ProximaNova;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.func-accordion__content {
  font-size: 16px;
  line-height: 24px;
  color: #131f3e;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.func-accordion__content ul {
  font-family: ProximaNova;
  margin-left: 24px;
  list-style: disc;
  padding: 0;
}

.func-accordion__content ul li {
  margin-top: 10px;
}

.func-accordion__content .soon-badge {
  padding: 4px;
  border-radius: 4px;
  font-weight: 900;
  font-size: 12px;
  line-height: 14px;
  background: linear-gradient(89.11deg, #4ebd74 38.71%, #a2f25e 145.73%);
  color: #ffffff;
  margin-right: 10px;
}

.roles-card {
  max-height: 324px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.roles-card_closed {
  max-height: 128px;
  overflow-y: hidden;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.roles-card_closed .roles-card__content {
  visibility: hidden;
  opacity: 0;
}

.roles-card__content ul {
  font-family: ProximaNova;
  padding: 0;
  list-style: none;
  margin: 0;
}

.text-blue {
  color: #4368c6;
}

.contact-info {
  padding-top: 10px;
}

.contact-info__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
}

.contact-info__title {
  font-family: ProximaNova;
  width: 104px;
  margin-right: 24px;
}

.contact-info__link {
  text-decoration: none;
  font-weight: 900;
}
</style>
